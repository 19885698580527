


import StudentCard from "./student_card.svelte";

const inflateElement = (element: any) => {
    
    // get data attributes named data-props
    if(element.getAttribute('inflated') === 'true') return;

    element.setAttribute('inflated', 'true')
    
    let { props: data, ...others } = element.dataset;
    data = data ? JSON.parse(data) : {};
    let props = Object.keys(others).map((key) => {
        return {
            [key]: JSON.parse(others[key])
        };
    }).reduce((acc, curr) => {
        return Object.assign(acc, curr);
    }, data);

    props = { ...props,
        // debug: true
    };

    const card = new StudentCard({
        target: element,
        props,
    });
};

document.querySelectorAll(".student-card")
.forEach(inflateElement);



// Create an observer instance
let observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      // If this mutation represents added nodes...
    if (mutation.addedNodes) {
    mutation.addedNodes.forEach((node:any) => {


        
        // ...and the added node is an element...
        if (node.nodeType === Node.ELEMENT_NODE) {

            function hasClass(node) {
                return 'classList' in node && node.classList.contains("student-card") || node.querySelector(".student-card")
            }

            console.log(node);
            
            if(hasClass(node)){
                var elements = document.querySelectorAll(".student-card[inflated='false']");
                elements.forEach(inflateElement);
            }
        }
    });
    }
    });
});

  // Set up the observer to watch the entire document and subtree for childList changes
observer.observe(document, { childList: true, subtree: true });