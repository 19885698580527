
<script>
    export let icon;
    export let css = "";

</script>

<style>
    .table-collapse{
        width:0.1%;
        white-space: nowrap;
    }
</style>


{#if icon}
    <td class="align-baseline table-collapse">
        <span class="material-icons {css}">{icon}</span>
    </td>
{:else}
    <td class="table-collapse"> <span class="material-icons {css}">block</span></td>
{/if}

<td class="align-middle text-sm pl-1"><slot/></td>

