<script>
    export let title;
    export let css;
</script>

<div class="bg-white {css}">
    {#if title}
        <div class="text-sm font-sm font-bold uppercase text-slate-400 bg-slate-700 text-white p-1">{title}</div>
    {/if}
    <div class="">
        <table class="w-full">
            <slot/>
        </table>
    </div>
</div>