<script>
    import { onMount } from 'svelte';

    export let context = "";
    
    export let data = '';  // a prop to receive JSON string

    onMount(() => {
        // Ensure clipboard API is available in the browser
        if (!navigator.clipboard) {
            console.error('Clipboard API not available');
        }
    });

    // Function to handle click event and copy to clipboard
    async function copyToClipboard() {
        try {
            const context = Object.assign(data,{context});
            await navigator.clipboard.writeText(JSON.stringify(context, null, 2));
            console.log('Copied to clipboard');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }
</script>

<div>
    <div>
        Error Generating JSON ({context})
    </div>
    <button on:click={copyToClipboard}>
        Clip to copy code to dev
    </button>
</div>
