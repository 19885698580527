<script>

    let props = $$props;
    export let student={
        fake:true,
        gender:""
    };
    export let client;
    export let lessonAddress="N/A"
    export let debug=false;
    export let studentProfile;
    export let relationShip;


    import Photo from "../shared/photo.svelte";
    import Page from "../shared/page.svelte";
    import Icon from "../shared/Icon.svelte";
    import Text from "../shared/Text.svelte";

    import JsonCopy from "../shared/JsonCopy.svelte";
    
    import InfoGrid from "../shared/InfoGrid.svelte";
    import CardItem from "../tutor_card.component/card-item.svelte"
    import IconText from "../tutor_card.component/icon-text.svelte";
    import dateformat from "dateformat";
    export let date_format = "dd/mm/yyyy";
    import CardRow from "../shared/cards/card-row.svelte";

    console.log(student);

    $: name = !student.fake? `${student.name} ${student.surname}`:"N/a";
    $: student_created = student.created_at?dateformat(student.created_at, date_format):"N/a";
    $: genderIcon = student.gender.toLowerCase() === 'male'? 'male' : student.gender.toLowerCase() ==='female'? 'female' : 'no_accounts'
    $: genderColor = genderIcon =='male'? 'text-blue-600': genderIcon == 'female'? 'text-red-300': 'text-slate-700'
</script>


{#if debug}
    <div>
        <pre>
            {JSON.stringify(props, null, 2)}
        </pre>
    </div>
{:else}
    <Page css="pb-2">
        <div class="flex flex-col select-none border bg-white w-full">
            <div class="flex flex-col w-full">
                <div class="flex flex-row w-full items-baseline bg-slate-900 text-white p-2 justify-between">
                    <div class="flex flex-row block items-center">
                        <span class="px-2">
                            <Photo url={student.photo_url} icon="school" />
                        </span>
                        <div>{name}</div>
                    </div>

                    
                    <a href="{`/students/edit/?clientId=${client.id}&id=${student.id}`}" target="_blank">
                        <Icon icon="edit" css="text-white" />

                    </a>
                </div>
            </div>


            <div class="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-1 md:gap-4 p-1 rounded-b-lg m-auto w-full md:w-11/12 py-4 text-slate-700">

                <CardItem title="Information">
                    <CardRow>
                        <IconText icon="person" css="text-slate-700">
                            {#if studentProfile?.relationship?.id != 0}
                                {relationShip}
                            {:else}
                                No relationship saved
                            {/if}
                        </IconText>
                    </CardRow>
                    <CardRow>
                        <IconText icon="{genderIcon}" css="{genderColor|| ''}">
                            {student.gender || "N/a"}
                        </IconText>
                    </CardRow>
                    <CardRow>
                        <IconText icon="school" css="text-slate-700">
                            {student.tutoring_details}
                        </IconText>
                    </CardRow>
                    <CardRow>
                        <IconText icon="description" css="text-slate-700">
                            Subjects:
                            {#if student && student.subjects}
                                {@html student.subjects}
                            {:else}
                                None saved
                            {/if}
                        </IconText>
                    </CardRow>
                    <CardRow>
                        <IconText icon="schedule">
                            Created: {student_created}
                        </IconText>
                    </CardRow>
                </CardItem>

                <CardItem title="Tutoring Preferences">
                    <CardRow>
                        <Text>
                            {student.tutoring_language}
                        </Text>
                    </CardRow>
                    <CardRow>
                        <Text>
                            Special needs:
                            {student?.studentProfile?.learning_disorders || 'None'}
                        </Text>
                    </CardRow>
                    <CardRow>
                        <Text>
                            Gender Preferences: {student.preferred_gender}
                        </Text>
                    </CardRow>
                    <CardRow>
                        <Text>
                            Venue: {lessonAddress}
                        </Text>
                    </CardRow>
                </CardItem>
            
            </div>
        </div>
    </Page>
{/if}
