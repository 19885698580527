
<script>
    export let css = "";
</script>

<td class="align-middle text-sm pl-1">
    <div class="{css}">
        <slot/>
    </div>
</td>

