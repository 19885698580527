
<script>
    export let css = "";
</script>

<style>
    .page{
        max-width: 1500px;
        margin: auto;
    }

</style>

<div class="page {css}">
    <slot/>
</div>