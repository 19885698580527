
<script>
    export let url;
    export let icon = "person"
</script>


<div class="w-12 h-12">
    {#if url}
        <img src="{url}}" alt="Avatar" class="w-12 h-12">
    {:else}
        <div class="w-12 h-12 bg-white rounded-full flex items-center justify-center">
            <span class="material-icons text-slate-600">{icon}</span>
        </div>
    {/if}
</div>